import withRoot from "../utils/withRoot";
import React from "react";
import { graphql, Link } from "gatsby";
import Seo from "../components/Seo";
// import Card from "../components/Card";
import Page from "../components/Page";
// import HomeFeatures from "../components/HomeFeatures";
// import Button from "@material-ui/core/Button";
// import Carousel from "../components/Carousel";
// import Avatar from "@material-ui/core/Avatar";
// import { Gift } from "mdi-material-ui";
import withStyles from "@material-ui/styles/withStyles";
// import irukaGIF from '../gifs/iruka-ani12.gif';
import Grid from "@material-ui/core/Grid";
// import BackgroundImage from 'gatsby-background-image';
import Img from "gatsby-image";
// import background from '../images/TOP_GIF.gif';
import background21 from '../images/2-1.png';
import background22 from '../images/2-2.png';
import Button from '@material-ui/core/Button';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import background3 from '../images/3.png';
import background4 from '../images/4.png';
import background5 from '../images/5.png';
import background6 from '../images/6.png';
import background7 from '../images/7.png';

const flag = typeof window !== 'undefined';

let jQuery;
let windowInstance;
let documentInstance;
if (flag) {
    jQuery = require('jquery');
    windowInstance = window;
    documentInstance = document;
}

const divMaxHeight = 120;

const styles = () => ({
  root: {
    fontWeight: "bold",
    // backgroundColor: '#000'
  },
});

const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff'
    }
  },
});

// const authIP = async(state) => {
//   var res = await fetch('/api/authIP', {
//     method: 'POST'
//   }).then((response) => {
//     // console.log(response)
//     return response.text();
//   });

//   // console.log(res)
//   if (res === 'Y') {
//     // console.log(state.state.disabled)
//     state.setState({disabled: false});
//   }
//   // console.log(state.state.disabled)
// }

class Home extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      disabled: false,
      seo: false,
      // scrollTopFlag: false,
      windowHeight: 0,
      siteHeight: 0,
      divTop: {},
      divHeight: {}
    }

    var url = this.props.location.href
    if (typeof(url) === 'string') {
      if (url.indexOf('staging') !== -1) {
        // console.log(this.state.disabled)
        this.state.disabled = true
        // console.log(this.state.disabled)
      } else {
        this.state.seo = true
      }
      // console.log(this.state.seo)
    }

    // authIP(this)
  }

  async componentDidMount() {
    windowInstance.addEventListener('scroll', this.handleScroll);
    if (this.state.disabled) {
      var res = await fetch('/api/authIP', {
        method: 'POST'
      }).then((response) => {
        // console.log(response)
        return response.text();
      });
      // console.log(res)
      if (res === 'Y') {
        // console.log(state.state.disabled)
        this.setState({disabled: false});
      }
      // console.log(state.state.disabled)
    }
    // console.log(jQuery('div.gatsby-image-wrapper'))
    jQuery('div.gatsby-image-wrapper').css('height', '');//.removeAttr('style');
    jQuery('div.gatsby-image-wrapper').css('width', '');
    jQuery('div.gatsby-image-wrapper').css('position', '');

    var insertDiv = {}
    var insertHeight = {}
    jQuery('div.img-plate').each(function() {
      insertDiv[jQuery(this).attr('id')] = jQuery(this).offset().top;
      insertHeight[jQuery(this).attr('id')] = jQuery(this).height();
      // console.log(jQuery(this).attr('id'));
      // console.log(jQuery(this).offset().top);
    });


    this.state.divTop = insertDiv
    this.state.divHeight = insertHeight
    // console.log(Object.keys(this.state.divTop))
    // console.log(this.state.divHeight['animation-2-right-title']);
    this.handleScroll();
  }

  renderSeo() {
    return (
      <Seo title="Home">
        <meta
          name="description"
          content="AristoBrainは、異分野融合により今まで解決できなかった課題を解決する、若い会社です"
        />
        <meta
          name="keywords"
          content="生命科学AI,生物科学AI,医療AI,行動予測AI,分野融合,企業変革,新規事業,データ分析,IoT,AI"
        />
      </Seo>
    )
  }

  handleScroll = () => {
    // if (this.state.scrollTopFlag) {

    var insertDic = {};
    var insertDiv = {};
    var insertHeight = {};
    var predivTop = Object.keys(this.state.divTop);
    // console.log(predivTop);
    if (this.state.siteHeight !== documentInstance.body.clientHeight) {
      insertDic['siteHeight'] = documentInstance.body.clientHeight;
      insertDic['windowHeight'] = windowInstance.innerHeight;
      jQuery('div.img-plate').each(function() {
        if (predivTop.includes(jQuery(this).attr('id'))) {
          insertDiv[jQuery(this).attr('id')] = jQuery(this).offset().top;
          insertHeight[jQuery(this).attr('id')] = jQuery(this).height();
          // console.log(jQuery(this).attr('id'));
          // console.log(jQuery(this).offset().top);
        }
      });
    }

    // var ua = navigator.userAgent;
    // console.log(ua);
    var top = windowInstance.scrollY;
    // // Edge, Safariの場合 
    // if(ua.indexOf('Edge') !== -1 || (windowInstance.chrome || 'WebkitAppearance' in documentInstance.documentElement.style)) {
    //   top = jQuery('body').scrollTop();
    // // Chrome, IE, Firefoxの場合
    // } else {
    //   top = jQuery('html').scrollTop();
    // }
    // console.log(top);
    // console.log(windowInstance.innerHeight)
    // console.log(insertDic);
    if (Object.keys(insertDic).length > 0) {
      if (Object.keys(insertDiv).length > 0) {
        insertDic['divTop'] = insertDiv
        insertDic['divHeight'] = insertHeight
      }
      this.setState(insertDic)
      // console.log(this.state.divTop)
      // console.log(this.state)
    }

    // console.log(top + this.state.windowHeight);
    // console.log(this.state.divTop['animation-2-right-title']);
    // console.log(top);
    // console.log(this.state.divTop['animation-2-right-title'] + this.state.divHeight['animation-2-right-title']);

    var dic = this.state;
    predivTop.forEach(function(key) {
      var direction, elem;
      [direction, elem] = key.split('-').slice(2);
      // console.log(direction)
      // console.log(elem)
      // console.log(top + dic.windowHeight);
      // console.log(dic.divTop[key]);
      // console.log(top);
      // console.log(dic.divTop[key] + dic.divHeight[key]);
      if ((top + dic.windowHeight >= dic.divTop[key]) && (top <= dic.divTop[key] + dic.divHeight[key])) {
        if (direction === 'top') {
          console.log('top-animation')
          jQuery('div#' + key).addClass('top-animation-fade-in-background');
          jQuery('div#' + key + ' > div > div > .introduction-logo').addClass('top-animation-fade-in-image');
          jQuery('div#' + key + ' > div > div > .image-center-text').addClass('top-animation-fade-in-text');
          jQuery('div#' + key + ' > div > div > p').addClass('top-animation-fade-in-title');
          jQuery('div#' + key + ' > div > div > h1').addClass('top-animation-fade-in-title');
          jQuery('div#' + key + ' > div > div > h4').addClass('top-animation-fade-in-title');
        } else if (direction === 'right') {
          if (elem === 'title') {
            jQuery('div#' + key).addClass('fade-in-image');
            jQuery('div#' + key + ' > .title-text').addClass('fade-from-right');
          } else {
            jQuery('div#' + key).addClass('fade-in-image');
            jQuery('div#' + key + ' > div > .title-text').addClass('fade-from-right');
            jQuery('div#' + key + ' > div > .section-text').addClass('fade-from-right-text');
            jQuery('div#' + key + ' > div > a > .whiteBottun').addClass('fade-in');
          }

        } else if (direction === 'left') {
          if (elem === 'title') {
            jQuery('div#' + key).addClass('fade-in-image');
            jQuery('div#' + key + ' > div > .title-text').addClass('fade-from-left');
            jQuery('div#' + key + ' > div > .section-text').addClass('fade-from-left-text');
            jQuery('div#' + key + ' > div > a > .whiteBottun').addClass('fade-in');
          } else {
            jQuery('div#' + key).addClass('fade-in-image');
            jQuery('div#' + key + ' > div > .section-text').addClass('fade-from-left-text');
            jQuery('div#' + key + ' > div > a > .whiteBottun').addClass('fade-in');
          }

        } else if (direction === 'center') {
          jQuery('div#' + key).addClass('fade-in-image');
          jQuery('div#' + key + ' > .center-title').addClass('fade-center');
          jQuery('div#' + key + ' > .center-text').addClass('fade-center-text');
        } else if (direction === 'none') {
          jQuery('div#' + key).addClass('fade-in-image');
        }
        // console.log(top + dic.windowHeight);
        // console.log(dic.divTop[key]);
        // console.log(top);
        // console.log(dic.divTop[key] + dic.divHeight[key]);
        delete dic.divTop[key];
        delete dic.divHeight[key];
      }
    });

    this.setState(dic);

    // insertDic['divTop'] = insertDiv
    // this.setState(insertDic)
    // console.log(this.state.siteHeight)
    // console.log(this.state.windowHeight)
    // console.log(this.state.divTop)
    // console.log(windowInstance.scrollY)



    // } else {
    //   this.setState({scrollTopFlag: true});
    // }
  }

  render() {
    // const products = props.data.allMarkdownRemark.edges;
    const {disabled} = this.state;
    // console.log(disabled)
    // if (this.props.location.href.indexOf('staging') != -1) {
    //   disabled = true
    // }

    if (disabled) {
      // console.log(this.state.disabled)
      return (
        <h1>You can't enter in this pages.</h1>
      );
    } else {
      return (
        // <div style={{overflowY: 'scroll'}} onScroll={this.handleScroll} ref={this.node}>
        <div id='animation-wrapper'>
          <Page url={this.props.location.pathname}>
            <ThemeProvider theme={theme}>
            {this.state.seo && this.renderSeo()}
              <Grid
                container
                direction='row'
                justify="space-between"
                spacing={0}
                alignItems='stretch'
              >
                <Grid xs={12} id='animation-1-top-title' className='img-plate animation-init' style={{backgroundImage: 'url(https://storage.googleapis.com/aristobrain_website/TOP_GIF.gif)', backgroundSize: 'cover', backgroundPosition: 'center'}}>
                  <div className='top-article'>
                    <div>
                      <Img
                        fixed={this.props.data.testFixed.childImageSharp.fixed}
                        alt="AristoBrain株式会社"
                        // style={{height: '4vw', width: '12vw', maxWidth: 200, maxHeight: 140, minWidth: 100, minHeight: 35}}
                        className='introduction-logo'
                        imgStyle={{width: '100%', height: 'auto'}}
                      />
                      <h2 className='image-center-text'>BEYOND POTENTIAL</h2>
                      <p>到達できなかった場所へ導いていく</p>
                      <h1>AristoBrain株式会社</h1>
                      <h4>with Deep Learning</h4>
                    </div>
                  </div>
                </Grid>
                <Grid xs={6} id='animation-2-right-title' className='img-plate animation-init' style={{backgroundImage: `url(${background21})`, backgroundSize: 'cover', backgroundPosition: 'left'}}>
                  {/* <BackgroundImage
                    Tag="section"
                    fluid={[props.data.twoFluid.childImageSharp.fluid, {media: `(object-fit: fill, position: absolute)`}]}
                    style={{height: '100%'}}
                  > */}
                    <h1 className='title-text'>
                      News
                    </h1>
                    {/* <p style={{margin: 0, height: '100%'}}>
                      <img src={irukaGIF} alt="Dolphin" style={{width: '100vw', height: '100%'}}/>
                    </p> */}
                    {/* <HomeFeatures /> */}
                  {/* </BackgroundImage> */}
                </Grid>
                <Grid xs={6} id='animation-3-left-text' className='img-plate animation-init' style={{backgroundImage: `url(${background22})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                  {/* <BackgroundImage
                    Tag="section"
                    fluid={[props.data.twoFluid.childImageSharp.fluid, {media: `(object-fit: fill, position: absolute)`}]}
                    style={{height: '100%'}}
                  > */}
                  <div>
                    <p className='section-text'>
                      AristoBrain株式会社のお知らせやプレスリリース。
                    </p>
                    <Link to='/news/'>
                      <Button
                        variant="contained"
                        color="primary"
                        className='whiteBottun'
                        size="large"
                      >
                        View More
                      </Button>
                    </Link>
                  </div>
                    {/* <p style={{margin: 0, height: '100%'}}>
                      <img src={irukaGIF} alt="Dolphin" style={{width: '100vw', height: '100%'}}/>
                    </p> */}
                    {/* <HomeFeatures /> */}
                  {/* </BackgroundImage> */}
                </Grid>
                <Grid xs={12} id='animation-4-right-text' className='img-plate animation-init' style={{backgroundImage: `url(${background3})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                  <div style={{width: '50vw'}}>
                    <h1 className='title-text'>About</h1>
                    <p className='section-text' style={{paddingTop: `calc(10vw / 4)`}}>
                      生命科学、神経科学、情報科学の知見をコアに、新しいサービスの開発を進めています。<br />
                      現在は、各パートナーと共に、がん医療、再生医療、生命保険の分野で、新しいサービス開発に取り組んでいます。
                    </p>
                    <Link to='/about/'>
                      <Button
                        variant="contained"
                        color="primary"
                        className='whiteBottun'
                        size="large"
                      >
                        View More
                      </Button>
                    </Link>
                  </div>
                  {/* <HomeFeatures /> */}
                </Grid>
                <Grid xs={6} id='animation-5-right-title' className='img-plate animation-init' style={{backgroundImage: `url(${background4})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                  {/* <BackgroundImage
                    Tag="section"
                    fluid={[props.data.twoFluid.childImageSharp.fluid, {media: `(object-fit: fill, position: absolute)`}]}
                    style={{height: '100%'}}
                  > */}
                    <h1 className='title-text'>
                      Service
                    </h1>
                    {/* <p style={{margin: 0, height: '100%'}}>
                      <img src={irukaGIF} alt="Dolphin" style={{width: '100vw', height: '100%'}}/>
                    </p> */}
                    {/* <HomeFeatures /> */}
                  {/* </BackgroundImage> */}
                </Grid>
                <Grid xs={6} id='animation-6-left-text' className='img-plate animation-init' style={{backgroundImage: `url(${background22})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                  {/* <BackgroundImage
                    Tag="section"
                    fluid={[props.data.twoFluid.childImageSharp.fluid, {media: `(object-fit: fill, position: absolute)`}]}
                    style={{height: '100%'}}
                  > */}
                  <div>
                    <p className='section-text'>
                      生命科学、神経科学、情報科学の知見を活用した新規事業創出・業務改善サービス。
                    </p>
                    <Link to='/service/'>
                      <Button
                        variant="contained"
                        color="primary"
                        className='whiteBottun'
                        size="large"
                      >
                        View More
                      </Button>
                    </Link>
                  </div>
                    {/* <p style={{margin: 0, height: '100%'}}>
                      <img src={irukaGIF} alt="Dolphin" style={{width: '100vw', height: '100%'}}/>
                    </p> */}
                    {/* <HomeFeatures /> */}
                  {/* </BackgroundImage> */}
                </Grid>
                <Grid xs={12} id='animation-7-right-text' className='img-plate animation-init' style={{backgroundImage: `url(${background5})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                  <div style={{width: '50vw'}}>
                    <h1 className='title-text'>Recruit</h1>
                    <p className='section-text' style={{paddingTop: `calc(10vw / 4)`}}>
                      高い志を持った仲間を広く募集しています。学歴や経験は問いません。
                    </p>
                    <Link to='/recruit/'>
                      <Button
                        variant="contained"
                        color="primary"
                        className='whiteBottun'
                        size="large"
                      >
                        View More
                      </Button>
                    </Link>
                  </div>
                  {/* <HomeFeatures /> */}
                </Grid>
                <Grid xs={6} id='animation-8-none-image' className='img-plate animation-init' style={{backgroundImage: `url(${background6})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                  {/* <BackgroundImage
                    Tag="section"
                    fluid={[props.data.twoFluid.childImageSharp.fluid, {media: `(object-fit: fill, position: absolute)`}]}
                    style={{height: '100%'}}
                  > */}
                    {/* <p style={{margin: 0, height: '100%'}}>
                      <img src={irukaGIF} alt="Dolphin" style={{width: '100vw', height: '100%'}}/>
                    </p> */}
                    {/* <HomeFeatures /> */}
                  {/* </BackgroundImage> */}
                </Grid>
                <Grid xs={6} id='animation-9-left-title' className='img-plate animation-init' style={{backgroundImage: `url(${background22})`, backgroundSize: 'cover', backgroundPosition: 'center'}}>
                  {/* <BackgroundImage
                    Tag="section"
                    fluid={[props.data.twoFluid.childImageSharp.fluid, {media: `(object-fit: fill, position: absolute)`}]}
                    style={{height: '100%'}}
                  > */}
                  <div>
                    <h1 className='title-text' >Partners</h1>
                    <p className='section-text' style={{paddingTop: `calc(10vw / 4)`}}>
                      一緒に新しいビジネスを作り上げるパートナーを広く募集しています。
                    </p>
                    <Link to='/partners/'>
                      <Button
                        variant="contained"
                        color="primary"
                        className='whiteBottun'
                        size="large"
                      >
                        View More
                      </Button>
                    </Link>
                  </div>
                    {/* <p style={{margin: 0, height: '100%'}}>
                      <img src={irukaGIF} alt="Dolphin" style={{width: '100vw', height: '100%'}}/>
                    </p> */}
                    {/* <HomeFeatures /> */}
                  {/* </BackgroundImage> */}
                </Grid>
                <Grid xs={12} id='animation-10-center-title' className='img-plate animation-init' style={{backgroundImage: `url(${background7})`, backgroundSize: 'cover', backgroundPosition: '50% 30%'}}>
                  <h1 className='center-title'>Contact</h1>
                  <p className='center-text'>
                    各種お問い合わせは、以下のお問い合わせボタンからお願いします。
                  </p>
                  {/* <HomeFeatures /> */}
                </Grid>
              </Grid>
              {/* <Card
                action={
                  <Button
                    className={props.classes.root}
                    color="secondary"
                    component={Link}
                    to="/products"
                    variant="contained"
                  >
                    View All Products
                  </Button>
                }
                avatar={
                  <Avatar>
                    <Gift />
                  </Avatar>
                }
                style={{ minHeight: 523 }}
                title="Our Products"
              >
                <Carousel items={products} />
              </Card> */}
            </ThemeProvider>
          </Page>
        </div>
      );
    }
  }
};

export const query = graphql`
  query {
    allFile(filter: { extension: { eq: "jpg" } }) {
      edges {
        node {
          publicURL
        }
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/products/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            image {
              publicURL
            }
            path
            title
            date(formatString: "DD MMMM YYYY")
          }
          excerpt
        }
      }
    }
    testFixed: file(relativePath: { eq: "AristoBrain株式会社.png" }) {
      childImageSharp {
        fixed(width: 150) {
            ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

export default withRoot(withStyles(styles)(Home));
